<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>

        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">contact_mail</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp;{{ $t("admin.setemail") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card class="elevation-0" :color="color.BG" id="thiscontainersetemailadmin">
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <v-layout row wrap class="pl-3 pr-3" justify-end>
                    <!-- <v-flex lg2 xs12> -->
                      <!-- <v-icon>mdi-email-plus</v-icon> -->
                      <!-- <v-text-field
                               prepend-icon="mdi-email-plus"
                                label="E-mail"
                                 outlined
                                 :color="color.theme"
                            ></v-text-field> -->
                      <v-btn :dark="color.darkTheme" :color="color.theme" @click="openaddemail()"
                        ><v-icon>add</v-icon>&nbsp;{{ $t("admin.addemail") }}</v-btn
                      >
                    <!-- </v-flex> -->
                    <!-- <v-flex lg1>
                            <v-btn class="ml-3" fab>เพิ่ม</v-btn>
                        </v-flex> -->
                  </v-layout>
                  <br />
                  <!-- Phonee -->
                  <div v-if="loaddataprogresstable === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <div v-if="resolutionScreen < 500">
                      <v-list two-line class="mb-6 pb-6 elevation-0" v-if="email.length !== 0">
                        <v-list-item v-for="item in paginatedData" :key="item.title" style="cursor: pointer;">
                          <v-list-item-content>
                            <v-list-item-title v-text="item.email"></v-list-item-title>
                            <!-- <v-list-item-title v-else v-text="item.name_eng"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.department_name"></v-list-item-subtitle> -->
                            <!-- <v-list-item-subtitle v-text="formatdatetime(item.process_dtm)"></v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-action >
                            <v-btn class="elevation-0" fab small @click="openrightclick($event,item.email)"><v-icon>more_vert</v-icon></v-btn>
                            <!-- <v-switch
                                @change="fn_confirm_set(item)"
                                v-model="item.status_admin"
                                inset
                                :color="color.theme"
                                ></v-switch> -->
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                    <!-- Labtop -->
                    <div v-else>
                      <v-data-table
                        :headers="header"
                        :items="email"
                        class="elevation-0"
                        :no-data-text="$t('tablefile.empty')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <template  v-slot:[`header.type`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:[`header.email`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template  v-slot:[`header.status`]="{ header }">
                          <span class="text-right" :style="headerTable">{{ $t(header.text) }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr>
                            <td width="7%" class="text-center">
                              <v-icon>contact_mail</v-icon>
                            </td>
                            <td width="86%">{{ props.item.email }}</td>
                            <td width="7%" class="text-right" >
                              <v-btn class="elevation-0" fab small @click="openrightclick($event,props.item.email)"><v-icon>more_vert</v-icon></v-btn>
                              <!-- <v-btn class="elevation-0" fab small><v-icon>mdi-border-color</v-icon></v-btn>
                              <v-btn class="elevation-0" fab small @click="fn_confirm_delete(props.item.email)"
                                ><v-icon>delete</v-icon></v-btn
                              > -->
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <!-- <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination> -->
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11 v-if="pageCount > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="page"
                          :length="pageCount || 0"
                          :color="color.theme"
                          @input="clickpagination()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
            <editemail
              :show="opendialogeditemail"
              :detaildata="detaildata"
              :email_all="email"
              @close="opendialogeditemail = false"
              @closereload="(opendialogeditemail = false), fn_get_email()"
              :key_email="key_email"
            ></editemail>
              <adminrightclick             
              :show="showadminMenu"
              :AreaX="x"
              :AreaY="y"
              @closeinboxmenu="showadminMenu = false"
              @loademail="fn_get_email()"
              :email="currentemail"
              :email_all="email"
              :file="[]"
              @deleteemail="fn_confirm_delete"
            ></adminrightclick>
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
import gbfGenerate from "../globalFunctions/generateAuthorize";

const adminrightclick = () => import("../components/contextmenu/adminrightclick");
const editemail = () => import("../components/optional/dialog-editemail");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_admin_toolbar,
    editemail,
    adminrightclick
  },
  data: function() {
    return {
      key_email:'',
      currentemail:"",
      showadminMenu: false,
      email: [],
      email_all:[],
      opendialogeditemail: false,
      loaddataprogresstable: false,
      detaildata: {},
      loaddataprogress: true,
      opendetaillog: false,
      opendownloadlog: false,
      size: 20,
      offset: 0,
      page: 1,
      x: 0,
      y: 0,
      listsize: [10, 20, 50, 100],
      header: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "admin.emailtext",
          align: "",
          value: "email",
          width: "86%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.email.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.email.slice(start, start + this.size);
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    openrightclick(e, email){
      console.log(email);
      e.preventDefault();
      this.showadminMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      console.log(this.x,this.y);
      this.$nextTick(() => {
        this.currentemail = email;
        setTimeout(() => {
          this.showadminMenu = true;
          console.log(this.showadminMenu);
        }, 300);
      });
    },
    //เปิดไดอาลอคเพิ่มอีเมล
    openaddemail() {
     
      console.log("email",this.email);
      this.opendialogeditemail = true;
      this.key_email = 'a'
     
    },
    //ลบอีเมล
   async fn_removeemail(_item) {
      this.loaddataprogresstable = true;
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        email_alert: [_item],
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/remove/email_alert_storage", payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: response.data.result,
            });
            this.loaddataprogresstable = true;
            this.fn_get_email();
          } else {
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: "error",
          });
        });
    },
    //ยืนยันการลบอีเมล
    fn_confirm_delete(item) {
      Swal.fire({
        title: this.$t("admintoast.confirmdeleteemail"),
        text: this.$t("admintoast.confirmdeleteemail_text") + item + " ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("admintoast.cancel"),
        confirmButtonColor: this.color.theme,
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("admin.submit"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fn_removeemail(item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    },
    //แก้ไขอีเมล
    fn_editemail() {},
    //เปลี่ยนหน้า
    clickpagination() {
      this.offset = (this.page - 1) * this.size;
      //   console.log(this.offset);
      //   console.log(this.page);
      //   console.log(this.size);

      // this.fn_get_email();
    },
    //ดึงข้อมูลอีเมลมาแสดง
   async fn_get_email() {
      this.loaddataprogresstable = true;
      this.email = [];
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
     let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get/email_alert_storage", payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            for (let i = 0; i < response.data.result.length; i++) {
              let data = {};
              data["email"] = response.data.result[i];
              this.email.push(data);
            }
            this.loaddataprogresstable = false;
          } else {
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            this.loaddataprogresstable = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loaddataprogresstable = false;
        });
    },
  },
  mounted() {
    this.fn_get_email();

    this.loaddataprogress = false;
  },
};
</script>
<style>
#thiscontainersetemailadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>

